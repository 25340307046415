<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="searchData(searchQuery)"
    />

    <div class="flex xs12">
      <search-bar @submit="searchData" />
    </div>

    <media-card
      class="flex xs12 sm6 md3 item"
      v-for="resource in resources"
      permission="Resources"
      :key="resource.id"
      :resource="resource"
      :owner="isOwner(resource.user_id)"
      @share="openShare"
      @download="openResource"
      @edit="editResource"
      @trash="tryDelete"
    />
    <infinite-loading
      ref="infinite"
      class="flex xs12"
      spinner="waveDots"
      @infinite="infiniteHandler"
    >
      <template v-slot:no-more>
        <div class="mb-4">
          {{ $t('layout.infinite.no_more') }}
        </div>
      </template>
      <template v-slot:no-results>
        <div class="mb-4">
          {{ $t('layout.infinite.no_results') }}
        </div>
      </template>
      <template v-slot:error="{ trigger }">
        <div class="mb-4">
          <span>{{ $t('layout.infinite.error') }}</span>
          <a
            href="javascript:;"
            @click="trigger"
          >
            {{ $t('layout.retry') }}
          </a>
        </div>
      </template>
    </infinite-loading>

    <fab-button
      :tooltip="$t('resources.new')"
      @clicked="newResource"
    />

    <va-modal
      size="large"
      v-model="newModal"
      :title="$t('resources.new')"
      :hide-default-actions="true"
    >
      <new-resource
        :modal="true"
        @update="updateModal"
      />
    </va-modal>

    <va-modal
      size="large"
      v-model="shareModal"
      :title="$t('tables.actions.share')"
      :hide-default-actions="true"
    >
      <share-bar :url="shareUrl" />
    </va-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const InfiniteLoading = () => import(/* webpackPrefetch: true */ 'vue-infinite-loading')
const NewResource = () => import(/* webpackPrefetch: true */ './New')
const SearchBar = () => import(/* webpackPrefetch: true */ './SearchBar')
const ShareBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Share')
const MediaCard = () => import(/* webpackPrefetch: true */ '@/components/extras/Cards/Media')
const FabButton = () => import(/* webpackPrefetch: true */ '@/components/extras/FabButton')

export default {
  name: 'resources-index',
  components: {
    FabButton,
    ShareBar,
    SearchBar,
    MediaCard,
    NewResource,
    InfiniteLoading,
  },
  data () {
    return {
      newModal: false,
      shareModal: false,
      error: false,
      tableData: [],
      resources: [],
      totalRecords: 0,
      lightboxImage: [],
      shareUrl: '',
      searchQuery: '',
      loading: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: '', // example: 'name'
          type: '', // 'asc' or 'desc'
        },
        page: 1,
        pageCount: 1,
        perPage: 50,
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    typeFields () {
      const types = [
        {
          name: this.$t('resources.types.docx'),
          value: 0,
        },
        {
          name: this.$t('resources.types.xlsx'),
          value: 1,
        },
        {
          name: this.$t('resources.types.pdf'),
          value: 2,
        },
        {
          name: this.$t('resources.types.jpg'),
          value: 3,
        },
        {
          name: this.$t('resources.types.mp4'),
          value: 4,
        },
        {
          name: this.$t('resources.types.mp3'),
          value: 5,
        },
        {
          name: this.$t('resources.types.pptx'),
          value: 7,
        },
      ]

      return types
    },
  },
  created () {
    // this.updateData()
  },
  methods: {
    isOwner (id) {
      return this.currentUser.super || id === this.currentUser.id
    },
    editResource (id) {
      this.$router.push({ name: 'resourcesEdit', params: { id: id } })
    },
    fixData (data) {
      // const nodef = this.$t('tables.undefined')
      for (const d of data) {
        const type = this.typeFields.find(t => t.value === d.type)
        d.type = type ? type.name : ''
      }

      return data
    },
    searchData (queryParams) {
      this.searchQuery = queryParams
      this.resources = []
      this.serverParams.page = 1
      this.serverParams.pageCount = 1
      this.$refs.infinite.stateChanger.reset()
    },
    apiUrl (params) {
      let route = 'resources'
      route += '?page=' + params.page || 0
      route += '&sort=created_at&direction=desc'
      route += this.searchQuery

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.totalRecords = response.data.pagination.count
      this.loading = false
    },
    async infiniteHandler ($state) {
      const params = this.serverParams
      if (params.page > params.pageCount) {
        return $state.complete()
      }

      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      const data = response.data.data
      this.serverParams.pageCount = response.data.pagination.page_count
      if (data.length) {
        this.serverParams.page += 1
        this.resources.push(...data)
        $state.loaded()
      } else {
        $state.complete()
      }

      this.loading = false
    },
    async searchByName (name) {
      if (name === '') return this.updateData()

      let response = false
      try {
        response = await this.$http.get('resources?q=' + name)
      } catch (err) {
        // console.log('Error searching country', err)
        this.loading = false
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.totalRecords = response.data.data.length || 0
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('resources/' + item.id)
      } catch (err) {
        // console.log('Error deleting resource', err)
        this.loading = false
        return
      }
      this.searchData(this.searchQuery)
    },
    async newResource () {
      this.newModal = true

      await this.$nextTick()
      window.dispatchEvent(new Event('resize'))
    },
    updateModal () {
      this.searchData(this.searchQuery)
    },
    openShare (path) {
      if (navigator.share) {
        return navigator.share({ url: path })
      }
      this.shareUrl = path
      this.shareModal = true
    },
    openResource (url) {
      console.log('url', url)
      window.open(url, '_blank')
    },
    async prepareResource (path) {
      this.loading = true

      try {
        await this.$http.head(path)
      } catch (e) {
        // console.log('Error getting resource', e)
        this.loading = false
        this.showToast(this.$t('notifications.network.missing'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 30000,
          fullWidth: false,
        })
        return
      }

      this.loading = false
      this.downloadResource(path)
    },
    downloadResource (path) {
      const link = document.createElement('a')

      link.setAttribute('href', path)
      link.setAttribute('download', '')
      link.setAttribute('target', '_blank')
      link.style.display = 'none'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
</script>
